import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import dataJSON from '../../assets/data/your-dreams.json'

import usePageQuery from '../../pageQuery'

import { Card, Button, Link } from './style'

type dataProps = {
  icon: string;
  title: string;
  text: string;
  price: string;
}

type YourDreamsProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const YourDreams = ({ setIsOpen, isMobile, oneLink }: YourDreamsProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 d-none d-xl-block'>
      <div className='container'>
        <div className='row align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-8 col-xl-5 mb-5 mb-xl-0'>
            <Img fluid={data.autoMotoYourDreams.fluid} alt='Homem sorridente segurando uma chave ao lado de sua nova moto.' />
          </div>
          <div className='col-12 col-md-8 col-lg-10 col-xl-6'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Só um passo pra alcançar o seu sonho
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 fw-400 mb-5 mb-xl-3'>
              Comece a planejar seu futuro hoje mesmo! São diversos valores de cartas pra você realizar seu sonho.
            </p>

            <div className='row'>
              {
                dataJSON.map((item: dataProps, index: number) => (
                  <div className='col-12 col-md-6 mb-3' key={item.price}>
                    <Card className={(index + 1) % 2 === 0 ? 'white' : 'black'}>
                      <div className='d-flex align-items-center justify-content-center mb-2'>
                        <OrangeDsIcon size='SM' color={ index % 2 === 0 ? '#ffffff' : '#161616' } icon={item.icon} />
                        <span className='title mb-0'>{item.title}</span>
                      </div>
                      <span className='text'>{item.text}</span>
                      <span className='price'>{item.price}</span>
                    </Card>
                  </div>
                ))
              }
              {
                isMobile ? (
                  <Link
                    href={oneLink}
                    onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      element_action: 'click button',
                      element_name: 'Saber mais no Super App',
                      section_name: 'Só um passo pra alcançar o seu sonho',
                    })
                    }}
                  >
                    Saber mais no Super App
                  </Link>
                ) : (
                  <Button
                    onClick={() => {
                      setIsOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_06',
                        element_action: 'click button',
                        element_name: 'Saber mais no Super App',
                        section_name: 'Só um passo pra alcançar o seu sonho',
                      })
                    }}
                  >
                    Saber mais no Super App
                  </Button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default YourDreams
