import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import contemplatedConsortiumJSON from '../../assets/data/contemplated-consortium.json'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { Section, Button, Card, Link } from './style'

type contemplatedConsortiumProps = {
  icon: string;
  title: string;
  description: string;
}

type ContemplatedConsortium = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const ContemplatedConsortium = ({ setIsOpen, isMobile, oneLink }: ContemplatedConsortium) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-4'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600'>
              <span className='d-lg-block'>Como ser </span>
              <span className='d-lg-block'>contemplado </span> no consórcio?
            </h2>
            <Button
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Conhecer no Super App',
                  section_name: 'Como ser contemplado no consórcio?',
                })
              }}
              className='d-none d-md-block'
            >
              Conhecer no Super App
            </Button>
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-8'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 150 }}
              xl={{ items: 2, partialVisibilityGutter: 15 }}
              containerClass='carousel'
              itemClass='px-2'
            >
              {
                contemplatedConsortiumJSON.map((item: contemplatedConsortiumProps) => (
                  <Card key={item.title}>
                    <div className='d-block mb-5'>
                      <OrangeDsIcon size='MD' color='#ff7a00' icon={item.icon} />
                    </div>
                    <h3 className='font-sora fs-20 lh-25 fs-24 lh-30 text-grayscale--500 fw-600 mb-3'>{item.title}</h3>
                    <p className='text-grayscale--500 fw-400 mb-0'>{item.description}</p>
                  </Card>
                ))
              }
            </DefaultCarousel>
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Conhecer no Super App',
                      section_name: 'Como ser contemplado no consórcio?',
                      redirect_url: 'https://intergo.app/f76718e8',
                    })
                  }}
                >
                  Conhecer no Super App
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Conhecer no Super App',
                      section_name: 'Como ser contemplado no consórcio?',
                    })
                  }}
                  className='d-md-none'
                >
                  Conhecer no Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ContemplatedConsortium
