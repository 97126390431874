import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

import dataJSON from 'src/assets/data/consorcio/comments.json'

import { Section, Button, Card, Link } from './style'

type dataProps = {
  image: string;
  name: string;
  information: string;
  description: string;
  type: string;
}

type OurClientsProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const OurClients = ({ setIsOpen, isMobile, oneLink }: OurClientsProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const comments = dataJSON.filter((item: dataProps) => item.type === 'AUTO')

  return (
    <Section className='py-5 d-none'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-lg-10 col-xl-5'>
            <div className='row'>
              <div className='col-3 mb-4'>
                <Img fluid={data.ourClientsAutoMotoStarts.fluid} alt='5 estrelas' />
              </div>
            </div>
            <h2 className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600 mb-4'>
              <span className='d-xl-block'>O que dizem nossos </span>
              clientes
            </h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 fw-400 mb-4 mb-xl-0'>
              A opinião de quem já contratou nosso Consórcio de Carros e Motos.
            </p>
            <Button onClick={() => setIsOpen(true)} className='d-none d-xl-block'>Contratar no Super App</Button>
          </div>
          <div className='col-12 col-lg-10 col-xl-6'>
            {
              comments.slice(0, 3).map((item: dataProps) => (
                <Card key={item.name}>
                  <div className='card-header'>
                    <img src={item.image} alt={item.name} />

                    <div>
                      <span className='card-header-text'>{item.name}</span>
                      <span className='card-header-description'>{item.information}</span>
                    </div>
                  </div>

                  <p className='fs-18 lh-22 text-grayscale--500 fw-400'>{item.description}</p>
                </Card>
              ))
            }
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Contratar no Super App',
                    section_name: 'O que dizem nossos clientes',
                  })
                  }}
                >
                  Contratar no Super App
                </Link>
              ) : (
                <Button
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Contratar no Super App',
                    section_name: 'O que dizem nossos clientes',
                  })
                  }}
                  className='d-xl-none'
                >
                  Contratar no Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OurClients
