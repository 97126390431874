import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import blogJSON from 'src/assets/data/consorcio/blog.json'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Card } from './style'

type blogProps = {
  id: string;
  title: string;
  introduction: string;
  grid_image_url: string;
  published_at: string;
  link: string;
}

const Blog = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 text-center fw-600 mb-3'>
              Blog do Inter
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-center fw-400 mb-5'>
              Acompanhe nosso blog e descubra as vantagens de um consórcio imobiliário.
            </p>
            <div className='row'>
              {
                blogJSON.map((item: blogProps) => (
                  <div className='col-12 col-md-4 mb-5 mb-md-0' key={item.id}>
                    <Card>
                      <img src={item.grid_image_url} />
                      <div className='content'>
                        <div className='d-flex align-items-center mb-3'>
                          <span className='content-inter'>Produtos Inter</span>
                          <span className='content-shop'>Inter Shop</span>
                        </div>

                        <h3 className='fs-20 lh-25 text-grayscale--500 fw-600 mb-2'>{item.title}</h3>
                        <span className='fs-12 lh-15 text-grayscale--300 fw-600 d-block mb-4'>{new Date(item.published_at).toLocaleDateString()}</span>
                        <p className='fs-16 lh-19 text-grayscale--500 fw-400'>{item.introduction}</p>
                        <div className='d-flex justify-content-end'>
                          <a
                            href={`https://blog.inter.co${item.link}`}
                            title='Leia mais'
                            target='_blank'
                            rel='noreferrer'
                            className='fs-14 lh-17 text-orange-extra'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_07',
                                element_action: 'click button',
                                element_name: 'Leia mais',
                                section_name: item.title,
                                redirect_url: `https://blog.inter.co${item.link}`,
                              })
                            }}
                          >
                            Leia mais
                            <OrangeDsIcon size='MD' color='#ff7a00' icon='arrow-right' className='ml-3' />
                          </a>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
