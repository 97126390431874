import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background: ${orange.extra};

  .carousel {
    .react-multi-carousel-dot  {
      button {
        background: ${white} !important;
      }
    }

    .react-multiple-carousel__arrow {
      svg {
        fill: ${white} !important;
      }
    }
  }
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: ${white};
  color: ${orange.extra};;
  font-weight: bold;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-top: 38px;

  &:focus { 
    outline: none;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
    max-width: 308px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 32px;
    max-width: 267px;
  }
`
export const Card = styled.div`
  min-height: 390px;
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  background: ${white};
  padding: 24px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 440px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 330px;
  }
  
  p {
    font-size: 18px;
    line-height: 22px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`
export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${white};
  color: ${orange.extra};
  font-weight: bold;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover { 
    outline: none;
    color: ${orange.extra};
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
    max-width: 308px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 32px;
    max-width: 267px;
  }
`
