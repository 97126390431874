import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      autoMotoHero: imageSharp(fluid: { originalName: { regex: "/auto-moto-hero/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      ourClientsAutoMotoStarts: imageSharp(fluid: { originalName: { regex: "/our-clients-auto-moto-starts/" }}) {
        fluid(maxWidth: 120, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      autoMotoYourDreams: imageSharp(fluid: { originalName: { regex: "/auto-moto-your-dreams/" }}) {
        fluid(maxWidth: 478, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      hubAutoMotohowToWork: imageSharp(fluid: { originalName: { regex: "/hub-auto-moto-how-to-work/" }}) {
        fluid(maxWidth: 615, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
