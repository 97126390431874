import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[100]};
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  border: none;
  margin-top: 32px;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-top: 40px;
  }
`

export const Card = styled.div`
  padding: 24px;
  background: ${white};
  box-shadow: 0px 12.1319px 36.3956px rgba(75, 78, 92, 0.1);
  border-radius: 16px;

  & + div {
    margin-top: 24px;
  }

  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 57px;
      height: 57px;
      border-radius: 50%;
      margin-right: 24px;
    }

    &-text {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: ${grayscale[400]};
      display: block;
    }

    &-description {
      font-size: 16px;
      line-height: 19px;
      color: ${grayscale[300]};
      display: block;
    }
  }
`
export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${orange.extra};
  border-radius: 8px;
  color: ${white};
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`
