import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'

import dataCMS from 'src/assets/data/consorcio/consorcio.json'

import { Section, Button, Link } from './style'

type consorcioBanner = {
  title: string;
  description: string;
  image: {
    url: string;
    alt: string;
  };
}

type heroProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const Hero = ({ setIsOpen, isMobile, oneLink }: heroProps) => {
  const data = usePageQuery()
  const cmsData: consorcioBanner[] = dataCMS
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 order-md-last mb-4 mb-mb-0'>
            {cmsData[1].image.url
            ? <img
                src={cmsData[1].image.url}
                alt={cmsData[1].image.alt}
                className='img-fluid float-lg-right'
              />
            : <Img
                fluid={data.autoMotoHero.fluid}
                alt='Mulher dirigindo um carro com um rapaz olhando o celular no banco do passageiro'
                className='float-lg-right'
              />}
          </div>
          <div className='col-12 col-md-6 col-lg-5 pr-xl-0'>
            <h1
              className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-grayscale--500 fw-600'
              dangerouslySetInnerHTML={{ __html: cmsData[1].title }}
            />
            <p
              className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400'
              dangerouslySetInnerHTML={{ __html: cmsData[1].description }}
            />
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Contratar agora no Super App',
                    section_name: 'Aproveite o Consórcio de auto e moto com parcelas reduzidas!',
                  })
                  }}
                >
                  Contratar agora no Super App
                </Link>
              ) : (
                <Button
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Contratar agora no Super App',
                    section_name: 'Aproveite o Consórcio de auto e moto com parcelas reduzidas!',
                  })
                  }}
                >
                  Contratar agora no Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
