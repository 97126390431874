import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'
import howToWorkJSON from '../../assets/data/howToWork.json'

import { Content, Button, Link } from './style'

type howToWorkProps = {
  title: string;
  description: string;
}

type HowToWorkProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const HowToWork = ({ setIsOpen, isMobile, oneLink }: HowToWorkProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-center justify-content-xl-between align-items-center'>
          <div className='col-12 col-md-10 col-lg-8 col-xl-6 order-xl-3'>
            <Img
              fluid={data.hubAutoMotohowToWork.fluid}
              className='float-xl-right'
              alt='Smartphone com o Super App do Inter aberto na tela de seguros auto e moto. '
            />
          </div>
          <div className='col-12 col-md-10 col-lg-8 col-xl-12 order-xl-1 mb-5'>
            <h2 className='font-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600 text-md-center mt-5 mt-xl-0'>
              <span className='d-block d-md-inline'>Como funciona o </span>
              consórcio?
            </h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--400 fw-400 text-md-center'>
              Entenda o que é e como funciona um consórcio.
            </p>
          </div>
          <div className='col-12 col-md-10 col-lg-8 col-xl-5 order-xl-2'>
            {
              howToWorkJSON.map((item: howToWorkProps) => (
                <Content key={item.title}>
                  <div className='content-text'>
                    <h3 className='font-sora fs-16 lh-20 fs-md-24 lh-md-30 text-grayscale--500 fw-600 mb-0'>{item.title}</h3>
                  </div>
                  <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 fw-400 mb-0'>{item.description}</p>
                </Content>
              ))
            }
          </div>
          <div className='col-12 col-md-10 col-lg-8 col-xl-5 order-last d-none'>
            {
              isMobile ? (
                <Link
                  href={oneLink}
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar app e contratar',
                    section_name: 'Como funciona o consórcio?',
                  })
                  }}
                >
                  Acessar app e contratar
                </Link>
              ) : (
                <Button
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar app e contratar',
                    section_name: 'Como funciona o consórcio?',
                  })
                  }
                  }
                >
                  Acessar app e contratar
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowToWork
