import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'

import Hero from './_sections/_hero/_index'
import Consortium from './_sections/_consortium/_index'
import HowToWork from './_sections/_how-to-work/_index'
import OurClient from './_sections/_our-clients/_index'
import ContemplatedConsortium from '../_section/_contemplated-consortium/_index'
import YourDreams from './_sections/_your-dreams/_index'
import Blog from './_sections/_blog/_index'
import Faq from '../_section/_faq/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import QRCodeAutoMoto from './assets/images/qr_consorcio_auto.png'
import pageContext from './pageContext.json'

const WIDTH_MD = 768

const autoMoto = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()
  const width = useWidth(300)

  const oneLink = ' https://intergo.app/aec17ebf'

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title={`Agora <a target="_blank" href="${oneLink}" class="text-orange--extra">abra nosso </a>app e contrate seu consórcio agora`}
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o app do Inter.'
        qrCode={QRCodeAutoMoto}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Layout pageContext={pageContext}>
      {hubModal}
      <Hero setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <Consortium />
      <HowToWork setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <OurClient setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <ContemplatedConsortium setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <YourDreams setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <Blog />
      <Faq />
    </Layout>
  )
}

export default autoMoto
